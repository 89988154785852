<template>
  <div style="width:100%;height:80vh">
    <!-- 搜索栏 -->
    <div>
      <div style="margin:15px 0;background:#fff;padding:10px">
        <a-form layout="inline">
          <a-form-item label="">
            <a-date-picker v-model:value="searchForm.startTime" @change="startChange" placeholder="开始日期" />
          </a-form-item>
          <a-form-item label="">
            <a-date-picker v-model:value="searchForm.endTime" @change="endChange" placeholder="结束日期" />
          </a-form-item>
          <a-form-item label="">
            <a-select v-model:value="searchForm.orgId" style="width: 150px" placeholder="选择部门">
              <a-select-option v-for="item in $store.state.app.orgList" :key="item.id" :value="item.id">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="">
            <a-input v-model:value="searchForm.empName" placeholder="业务员" />
          </a-form-item>
          <a-space>
            <div class="operate-btn">
              <a-button class="header-search-btn" @click="searchData" :loading="loading">搜索</a-button>
              <a-button class="header-reset-btn" @click="resetData" :loading="loading">重置</a-button>
            </div>
          </a-space>
        </a-form>
      </div>
    </div>
    <!-- 内容 -->
    <div style="height:100%;display: flex;justify-content: space-between;">
      <div style="width:35%;height:100%;display:flex;flex-direction: column">
        <!-- 跟单异常数 -->
        <div class="follow-abnormal">
          <div class="follow-abnomall-div">
            <div class="left-div">
              <div style="font-weight:bold;font-size:26px;">{{ departmentData.docOrderNum }}</div>
              <div style="font-size:16px">跟单异常订单数</div>
            </div>
            <div class="right-div">
              占比总{{ departmentData.docOrderRate }}%
            </div>
          </div>
          <div class="follow-abnomall-div">
            <div class="left-div">
              <div style="font-weight:bold;font-size:26px;">{{ departmentData.massLossNum }}</div>
              <div style="font-size:16px">车辆质损总数</div>
            </div>
            <div class="right-div">
              占比总{{ departmentData.massLossRate }}%
            </div>
          </div>
          <div class="follow-abnomall-div">
            <div class="left-div">
              <div style="font-weight:bold;font-size:26px;">{{ departmentData.timeNum }}</div>
              <div style="font-size:16px">时效异常总数</div>
            </div>
            <div class="right-div">
              占比总{{ departmentData.timeNumRate }}%
            </div>
          </div>
        </div>
        <!-- 部门异常统计 -->
        <div class="abnormal-statistics">
          <div style="height:100%;width:auto" id="departmentAbnormal"></div>
        </div>
      </div>
      <div style="width:64%;height:100%;">
        <div class="mass-loss">
          <div style="height:100%;width:auto" id="massLoss"></div>
        </div>
        <div class="ageing-abnomal">
          <div style="height:100%;width:auto" id="ageingAbnomal"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { departmentAbnormal, massLossData, ageingAbnomalData } from '../echarts/echarts.js'
import { orgDocHistogram, employeeDocStatistic } from '@/api/abnormalStatistics/abnormalStatistics'

export default {
  setup () {
    const state = reactive({
      loading: false,
      departmentData: {},
      searchForm: {
        startTime: null,
        endTime: null,
        orgId: null,
        empName: ''
      },
      mirrorSearch: {}
    })
    onMounted(() => {
      loadData()
      state.mirrorSearch = JSON.stringify(state.searchForm)
    })
    const loadData = async () => {
      state.loading = true
      departmentalData()
      getMassLossData()
      getAgeingAbnomalData()
    }
    const startChange = (e, v) => {
      state.searchForm.startTime = v
    }
    const endChange = (e, v) => {
      state.searchForm.endTime = v
    }
    const searchData = () => {
      loadData()
    }
    const resetData = () => {
      state.searchForm = JSON.parse(state.mirrorSearch)
      loadData()
    }
    const departmentalData = () => {
      orgDocHistogram({
        ...state.searchForm
      }).then(async res => {
        if (res.code === 10000) {
          state.departmentData = res.data
          const object = {
            list1: [],
            list2: [],
            list3: []
          }
          res.data.data.forEach(item => {
            if (item.documentaryType.value === 1) object.list1 = item.docNumList
            if (item.documentaryType.value === 2) object.list2 = item.docNumList
            if (item.documentaryType.value === 3) object.list3 = item.docNumList
          })
          await departmentAbnormal('departmentAbnormal', state.departmentData.orgName, object)
        }
      })
    }
    const getMassLossData = () => {
      employeeDocStatistic({
        ...state.searchForm,
        documentaryType: 3 // 2时效 3质损
      }).then(async res => {
        if (res.code === 10000) {
          const array = []
          const legend = []
          res.data.data.forEach(item => {
            legend.push(item.empName)
            array.push({
              type: 'line',
              smooth: true,
              name: item.empName,
              data: item.docVehicleNum
            })
          })
          await massLossData('massLoss', res.data.dateTime, legend, array)
          if(document.querySelector('#massLoss').getAttribute('_echarts_instance_')) document.querySelector('#massLoss').removeAttribute('_echarts_instance_')
        }
      })
    }
    const getAgeingAbnomalData = () => {
      employeeDocStatistic({
        ...state.searchForm,
        documentaryType: 2 // 2时效 3质损
      }).then(async res => {
        if (res.code === 10000) {
          const array = []
          const legend = []
          res.data.data.forEach(item => {
            legend.push(item.empName)
            array.push({
              type: 'line',
              smooth: true,
              name: item.empName,
              data: item.docVehicleNum
            })
          })
          await ageingAbnomalData('ageingAbnomal', res.data.dateTime, legend, array)
          if(document.querySelector('#ageingAbnomal').getAttribute('_echarts_instance_')) document.querySelector('#ageingAbnomal').removeAttribute('_echarts_instance_')
        }
      }).finally(() => { state.loading = false })
    }
    return {
      ...toRefs(state),
      searchData,
      resetData,
      startChange,
      endChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>